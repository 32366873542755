import { Fade } from "react-reveal";
import YoutubeEmbed from "./integration/YoutubeEmbed";
import bdd_project from "../img/project_bdd.png"
import schotten_project from "../img/project_schotten.png"

const projects = [
    {
        title : "Mapless Navigation",
        desc: "Conception and implementation of a mapless navigation system for autonomous vehicle.",
        tags: [
            ["Python", "red"],
            ["Cython", "red"],
            ["OpenCV", "yellow"],
            ["Pandas", "yellow"],
            ["NumPy", "yellow"],
            ["ROS", "yellow"],
            ["Computer Vision", "green"],
            ["Pure Pursuit", "purple"],
            ["CNN", "purple"],
            ["YOLO Detection", "purple"],
        ],
        img: <YoutubeEmbed embedId="C_PFLlK2Gto"/>,
        link: "https://github.com/Benjouzz/Autonomous-vehicle-mapless-navigation-system-main",
        color: "red"
    },
    {
        title : "Big Data Derby",
        desc: "Analyze horse racing data to improve the health of the horse and strategy of competition.",
        tags: [
            ["Python", "red"],
            ["Pandas", "yellow"],
            ["NumPy", "yellow"],
            ["EDA", "green"],
        ],
        img: <img src={bdd_project} alt='Kaggle Competition - Big Data Derby Project'></img>,
        link: "https://www.kaggle.com/code/benjouzzz/bigdataderby-eda-data-augmentation",
        color: "yellow"
    },
    {
        title : "Bookstore",
        desc: "E-commerce website including a recommendation engine.",
        tags: [
            ["Python", "red"],
            ["SQL", "red"],
            ["Pandas", "yellow"],
            ["Sklearn", "yellow"],
            ["NLTK", "yellow"],
            ["MySQL", "yellow"],
            ["NLP", "green"],
            ["EDA", "green"],
            ["Sentiment Analysis", "green"],
            ["Content-Based Filtering", "green"],
            ["Collaborative Filtering", "green"],
        ],
        img: <YoutubeEmbed embedId="Bsi7pE-CKNc"/>,
        link: "https://github.com/Benjouzz/DS50_backend",
        color: "green"
    },
    {
        title : "Schotten Totten",
        desc: "Schotten Totten game implemented with Python and PyGame. An AI is added to the game.",
        tags: [
            ["Python", "red"],
            ["OOP", "yellow"],
            ["MinMax Algo", "purple"]
        ],
        img: <img src={schotten_project} alt='Schotten Totten Project'></img>,
        link: "https://github.com/Benjouzz/Projet-IA41",
        color: "blue"
    },
]


function Card(args) {
    const color_class = " "+args.color+" b"+args.color

    return (
        <a className={'project_card d-flex flex-column p-4 mx-auto '+color_class} href={args.link}>
            {args.img}
            <h4 className="mt-4">{args.title}</h4>
            <p className="desc">{args.desc}</p>
            <div className="tags d-flex gap-2 mt-3 flex-wrap">
                {args.tags.map((tag) => {
                    return (
                        <div className={"tag "+tag[1]+" b"+tag[1]} >
                            <span className="taglabel px-2 py-1">{tag[0]}</span>
                        </div>
                    )
                })}
            </div>
        </a>
    );
}


function Project() {
    return (
        <div id="project" className='section'>
            <h2 className="mb-5">projects</h2>
            <div className="d-flex justify-content-between flex-wrap row-gap-5 pb-5">
                {projects.map((proj) => {
                    return <Card title={proj.title} desc={proj.desc} tags={proj.tags} img={proj.img} link={proj.link} color={proj.color}/>
                })}
            </div>
        </div>
    );
}

export default Project;