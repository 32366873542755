import particlesConfig from "./effect/particles";
import Particles from "react-tsparticles";
import Typewriter from 'typewriter-effect';
import { Fade } from "react-reveal";

const tw_strings = [
    "Hello, I'm Benjamin STACH.",
    "A passionnate Data Science & AI Engineer.",
    "Let's meet each other !",
    ""
]

function Hero() {
    return (
        <div id='home' className='hero'>
            <div class='transition-mask'></div>
            <Particles id='particles1' params={particlesConfig}></Particles>
            <Fade bottom>
                <div className='herotext h4'>
                    <h1>Benjamin STACH</h1>
                    <Typewriter className=".h4"
                        options={{
                            loop: true,
                            autoStart: true,
                            strings: tw_strings,
                        }}
                    />
                </div>
            </Fade>
        </div>
    );
}

export default Hero;