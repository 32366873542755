import React from 'react';

import Nav from './components/Nav';
import Hero from './components/Hero';
import Aboutme from './components/Aboutme';
import Service from './components/Service';
import Skill from './components/Skill';
import Project from './components/Project';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
  return (
    <div className='App'>
      <Nav/>
      <Hero/>
      <div className='body d-flex flex-column gap-2'>
        <Aboutme/>
        <Service/>
        <Skill/>
        <Project/>
      </div>
      <Contact/>
      <Footer/>
    </div>
  );
};

export default App;
