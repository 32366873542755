import { MdEmail } from 'react-icons/md';
import { BsLinkedin } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { HiLocationMarker } from 'react-icons/hi';
import emailjs from '@emailjs/browser';
import { useRef } from "react";

import Typewriter from 'typewriter-effect';

const tw_strings = [
    "Interested in working together ?",
    "Contact me.",
    ""
]


function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_3c3rbso','template_sidu9rm', form.current, '9quzh5AC_CGFqBQOv')
        e.target.reset()
    }

    return (
        <div id="contact" className="contact d-flex flex-column justify-content-center gap-5" >
            <div className="d-flex justify-content-center align-items-center h4 typewriter2">
                <Typewriter
                    options={{
                        loop: true,
                        autoStart: true,
                        strings: tw_strings,
                    }}
                />
            </div>
            <div class='d-flex justify-content-center gap-5 align-items-center flex-wrap'>
                <div class='contacts d-flex flex-column gap-3'>
                    <a class='contact_card bred d-flex align-items-center py-3 bred red' href="https://www.linkedin.com/in/benjamin-stach-1bb3741b2">
                        <BsLinkedin class="contact-icon red"/>
                        <p>Benjamin STACH</p>
                        <MdOutlineKeyboardArrowRight class="contact-icon arrowstyle"/>
                    </a>
                    <a class='contact_card byellow d-flex align-items-center py-3 byellow yellow' href="mailto:benjamin.stach@utbm.fr">
                        <MdEmail class="contact-icon yellow"/>
                        <p>benjamin.stach@utbm.fr</p>
                        <MdOutlineKeyboardArrowRight class="contact-icon arrowstyle"/>
                    </a>
                    <a class='contact_card bgreen d-flex align-items-center py-3 bgreen green' href="https://api.whatsapp.com/send?phone=0677203668">
                        <IoLogoWhatsapp class="contact-icon green"/>
                        <p>+336 77 20 36 68</p>
                        <MdOutlineKeyboardArrowRight class="contact-icon arrowstyle"/>
                    </a>
                    <div class='contact_card bblue d-flex align-items-center py-3 bblue blue'>
                        <HiLocationMarker class="contact-icon blue"/>
                        <p>Mulhouse, France</p>
                    </div>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <h5>name</h5>
                    <input type="text" name="name" placeholder="your full name" className="bred" required></input>
                    <h5>email</h5>
                    <input type="email" name="email" placeholder="your email" className="byellow" required></input>
                    <h5>message</h5>
                    <textarea name="message" rows="7" placeholder="your message" className="bgreen" required></textarea>
                    <button class='p' type="submit">send email<MdOutlineKeyboardArrowRight className="arrowstyle bblue"/></button>
                </form>
            </div>
        </div>
    );
}

export default Contact;