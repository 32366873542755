import { useState } from 'react';
import { Link } from 'react-scroll';

const Nav = () => {
    
    const [menuOpen, setMenuOpen] = useState(false);
    const menuToggle = () =>{
        setMenuOpen(!menuOpen)
    };
    const renderToggle = () =>{
        let classes = "toggleb"
        if (menuOpen){
            classes += " active"
        }
        return classes
    }

    return (
        <header>
            <div className='header'>
            <a className='logo' href="/">
                <h3 style={{display: 'contents'}}>&lt;&gt;</h3>
                <h5>myfolio</h5>
            </a>
            <div className={renderToggle()} onClick={menuToggle}>
                <div id="togtop" class='togglebar'></div>
                <div id="togmid" class='togglebar'></div>
                <div id="togbot" class='togglebar'></div>
            </div>
            </div>
            <nav className={menuOpen? 'active':null}>
                <ul>
                    <Link className="link h6 menu" onClick={menuToggle} to="aboutme" smooth={true} spy={true} offset={0} duration={500}>about me</Link>
                    <Link className="link h6 menu" onClick={menuToggle} to="service" smooth={true} spy={true} offset={0} duration={500}>services</Link>
                    <Link className="link h6 menu" onClick={menuToggle} to="skill" smooth={true} spy={true} offset={0} duration={500}>skills</Link>
                    <Link className="link h6 menu" onClick={menuToggle} to="project" smooth={true} spy={true} offset={0} duration={500}>projects</Link>
                    <Link className="link h6 menu" onClick={menuToggle} to="contact" smooth={true} spy={true} offset={0} duration={500}>contact</Link>
                </ul>
            </nav>
        </header>
        );
}

export default Nav;