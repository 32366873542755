import {SiDatabricks} from "react-icons/si";
import {GoGear} from "react-icons/go";
import {IoIosEye} from "react-icons/io";
import {VscCode} from "react-icons/vsc";
import { Fade } from "react-reveal";

const cards = [
    {
        title: "Data Science",
        desc: `Cleaning and preparing data for analysis. Working with large and complex data sets. Analysing by using statistical and analytical techniques to identify patterns, trends, and insights.
            Creating visual representations of data using charts, graphs, and provide a better understanding of the insights gained from data.
            Helping businesses making data-driven decisions by analyzing data and modeling techniques.
            `,
        icon: <SiDatabricks/>,
        color: "red"
    },
    {
        title: "Machine Learning",
        desc: `Developing and implementing ML models for various applications such as anomaly detection, predictive maintenance, regression and natural language processing.
            Building and maintaining ML infrastructure, including data pipelines, training and inference systems, and deployment pipelines.
            Integrating ML models into existing systems and applications, such as web services, mobile apps, and databases.
            `,
        icon: <GoGear/>,
        color: "yellow"
    },
    {
        title: "Computer Vision",
        desc: `Developing and implementing computer vision algorithms and models for various applications such as object recognition, tracking, and image segmentation.
            Designing and optimizing computer vision systems to improve their performance, accuracy, and efficiency.
            Integrating computer vision models into existing systems and applications, such as autonomous vehicles, drones, and security systems.`,
        icon: <IoIosEye/>,
        color: "green"
    },
    {
        title: "Development",
        desc: `Designing and implementing web applications using state-of-the-art frameworks.
            Developing and implementing back-end services and APIs using popular server-side technologies.
            Developing and maintaining databases, including designing data schemas, optimizing database queries, and ensuring data security and integrity.
            `,
        icon: <VscCode/>,
        color: "blue"
    }
]

function Card(args) {
    const color_class = " "+args.color+" b"+args.color

    return (
        <div
            className={"d-flex align-items-center py-3 px-4 service_card"+color_class}
        >
            <div className="service_icon d-flex align-items-center justify-content-center">
                {args.icon}
            </div>
            <div>
                <h5>
                    {args.title}
                </h5>
                <p>
                    {args.desc}
                </p>
            </div>
        </div>
    )
}

const Service = () => {
    return (
        <div id="service" className="section">
            <h2 className="mb-5">services</h2>
            <div>
                <p>
                    
                </p>
            </div>
            <div className="d-flex flex-column gap-3 mt-5">
                {cards.map((card) => {
                    return <Card title={card.title} desc={card.desc} icon={card.icon}  color={card.color}/>
                })}
            </div>
        </div>
    );
}

export default Service;