import { Fade } from "react-reveal";
import resume from "../dl/BSTACHCV.pdf";

function Aboutme() {

    return (
        <div id="aboutme" className="section">
            <h2 className="mb-5">about me</h2>
            <div>
                <p>Hi I am <strong>Benjamin STACH</strong> &#x270B;,</p>
                <p>
                I'm a <strong>Junior Data Scientist</strong> with a passion for data-driven solutions.
                Specialized in <strong>Data Science and AI</strong>, I'm about to graduate from <strong>UTBM</strong> (Université de Technologie de Belfort-Montbéliard) with a <strong>Master Degree in Computer Science Engineering</strong>.
                Currently, I'm working at <strong>Orange</strong> (Leading Telecom operator in France) where I joined the <strong>DATA-AI INNOVATION</strong> team to achieve my end-of-study project.
                </p>
                <p>
                I am deeply interested in the cutting-edge field of <strong>Generative AI</strong> and <strong>NLP</strong>, more precisely state-of-the-art neural network architectures.
                Exploring the complexities of these technologies has captivated me as I continually seek to learn more about it.
                Besides that, I really enjoy <strong>Software Development</strong>.
                </p>
                <p>
                As a <strong>Data scientist</strong>, my goal is to leverage my skills and expertise to help organizations make informed decisions based on data.
                I'm constantly looking for new challenges and opportunities to expand my knowledge and skills, and I'm excited to see what the future holds for the field of data science.
                </p>
                <p>
                More personally, I'm keen on travelling.
                Exploring diverse places, immersing in different cultures, and connecting through language fascinates me.
                That's the reason why I am completely open to all opportunities to work abroad.
                </p>
                <p>
                If you want to know more about me, I invite you to look at my resume down bellow. &#128071;
                </p>
            </div>
            <a className="resume-btn py-2 px-4 " href={resume} target="_blank">
                <p>See Resume</p>
            </a>
        </div>
    );
}

export default Aboutme;